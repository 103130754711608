@import '../../mixins';

.review-modal {
  & .review-card {
    padding: 0;

    border: none;
    border-radius: 0;

    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }
    
    @include hover {
      box-shadow: none;
    }

    &__head {
      padding-bottom: rem(20);
      
      align-items: end;
      flex-direction: row;

      border-bottom: solid 1px var(--stroke-dark);

      @include mediaBigDesktop {
        padding-bottom: big(20);

        border-bottom-width: big(1);
      }
    }

    &__content {
      & p {
        -webkit-line-clamp: unset;
      }
    }

    &__more {
      display: none;
    }
  }
}
